import React, { useEffect, useState, useRef } from 'react';
import {
    Marker,
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    DirectionsRenderer,
} from 'react-google-maps'
import { MAP } from 'react-google-maps/lib/constants'
import { InfoBox } from 'react-google-maps/lib/components/addons/InfoBox';
import { compose, withProps } from "recompose";
import { MAP_SETTINGS } from '../../constants/constants';

import dropIcon from '../../assets/images/dropIcon.png'
import driverIcon from '../../assets/images/driverIcon.png'
import dropoff from '../../assets/images/dropoff.png'
import pickup from '../../assets/images/pickup.png'
import GoogleMapReact from 'google-map-react';
let directionsRenderer;
let driverMarker;

const {
    MARKER_SIZE,
    DEFAULT_ZOOM,
    DEFAULT_CENTER,
    DEFAULT_MAP_OPTIONS,
    // PIXEL_OFFSET,
    DIRECTIONS_OPTIONS,
} = MAP_SETTINGS

const DIRECTION_REQUEST_DELAY = 300

const delay = (time) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, time)
    })

const directionsRequest = ({ DirectionsService, origin, destination, waypoint }) =>
    new Promise((resolve, reject) => {
        DirectionsService.route(
            {
                origin: new window.google.maps.LatLng(origin.lat, origin.lon),
                destination: new window.google.maps.LatLng(
                    destination.lat,
                    destination.lon
                ),
                travelMode: window.google.maps.TravelMode.DRIVING,
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: window.google.maps.TrafficModel.OPTIMISTIC
                }
            },
            (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    resolve(result)
                } else {
                    reject(status)
                }
            }
        )
    })
let mapInstance;
const MapContainer = (props => {
    let { origins, destinations, driver, rideStart, delivered, driver_details, coordinates } = props;
    const [directions, setDirections] = useState([]);
    const [currentDriver, setCurrentDriver] = useState();
    const [ETA, setETA] = useState(null);
    const [map, setMap] = useState()
    const mapRef = React.useRef(null)
    const dirRef = React.useRef(null)
    useEffect(() => {
        if (driver) {
            setCurrentDriver(currentDriver)
        }
        else if (driver_details && driver_details?.location) {
            setCurrentDriver({ lon: driver_details.location.coordinates[0], lat: driver_details.location.coordinates[1] })
        }
    }, [])

    // const [driver] = useState([{ coordinates: { lat: 30.703691669459992, lon: 76.70428130822862 } }])

    useEffect(() => {

        if (driver) {
            setCurrentDriver(driver)
        }
    }, [driver])


    useEffect(() => {
        if (window.google) {
            const bounds = new window.google.maps.LatLngBounds()
            origins.forEach(({ coordinates: { lat, lon } }) => {
                bounds.extend(new window.google.maps.LatLng(lat, lon))
            })
            destinations.forEach(({ coordinates: { lat, lon } }) => {
                bounds.extend(new window.google.maps.LatLng(lat, lon))
            })
            // currentDriver.forEach(({ coordinates: { lat, lon } }) => {
            // currentDriver.forEach(({ coordinates: { lat, lon } }) => {
            if (driver) {
                bounds.extend(new window.google.maps.LatLng(driver.lat, driver.lon))
            }
            // })
            // map.fitBounds(bounds)
            const DirectionsService = new window.google.maps.DirectionsService();
            const MatrixService = new window.google.maps.DistanceMatrixService();
            if (!directionsRenderer) {
                directionsRenderer = new window.google.maps.DirectionsRenderer({ suppressMarkers: true });
            }
            if (driverMarker) {
                driverMarker.setMap(null)
            }
            destinations.map(({ coordinates: { lat, lon }, id }) => (
                new window.google.maps.Marker({
                    position: { lat: lat, lng: lon },
                    map,
                    icon: {
                        url: dropoff,
                        scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
                    }
                })
            ))

            origins.map(({ coordinates: { lat, lon }, id }) => (
                new window.google.maps.Marker({
                    position: { lat: lat, lng: lon },
                    map,
                    icon: {
                        url: pickup,
                        scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
                    }
                })
            ))

            driverMarker = new window.google.maps.Marker({
                position: { lat: driver?.lat || currentDriver?.lat, lng: driver?.lon || currentDriver?.lon },
                map,
                icon: {
                    url: driverIcon,
                    scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
                },
                optimized: false,
                zIndex: 99999999
            });
            driverMarker.setMap(map)


            const fetchDirections = async () => {
                const tempDirectionsToOrigin = []
                if (dirRef && dirRef.current) {
                    try {
                        dirRef.current.setMap(null)
                        dirRef.current.set('directions', null)
                    } catch (e) { }
                }
                let direction;
                if (!driver) {
                    direction = await directionsRequest({
                        DirectionsService,
                        origin: {
                            lat: origins[0].coordinates.lat,
                            lon: origins[0].coordinates.lon,
                        },
                        destination: {
                            lat: destinations[0].coordinates.lat,
                            lon: destinations[0].coordinates.lon,
                        },
                    })
                    const request = {
                        origins: [{
                            lat: origins[0].coordinates.lat,
                            lng: origins[0].coordinates.lon,
                        }],
                        destinations: [{
                            lat: destinations[0].coordinates.lat,
                            lng: destinations[0].coordinates.lon,
                        }],
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        unitSystem: window.google.maps.UnitSystem.METRIC,
                        avoidHighways: false,
                        avoidTolls: false,
                        drivingOptions: {
                            departureTime: new Date(),
                            trafficModel: window.google.maps.TrafficModel.OPTIMISTIC
                        }
                    };
                    const MatRes = await MatrixService.getDistanceMatrix(request);
                    console.log(MatrixService, "MatrixService");
                    const { rows } = MatRes;
                    const ETA = rows[0].elements[0].duration_in_traffic.text;
                    console.log(MatRes, "ETA", ETA, driver, "driver");
                    setETA(ETA)

                } else if (driver && driver?.lat && driver?.lon) {
                    direction = await directionsRequest({
                        DirectionsService,
                        origin: {
                            lat: driver.lat,
                            lon: driver.lon,
                        },
                        destination: {
                            lat: destinations[0].coordinates.lat,
                            lon: destinations[0].coordinates.lon,
                        }
                    })

                    const request = {
                        origins: [{
                            lat: driver.lat,
                            lng: driver.lon,
                        }],
                        destinations: [{
                            lat: destinations[0].coordinates.lat,
                            lng: destinations[0].coordinates.lon,
                        }],
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        unitSystem: window.google.maps.UnitSystem.METRIC,
                        avoidHighways: false,
                        avoidTolls: false,
                        drivingOptions: {
                            departureTime: new Date(),
                            trafficModel: window.google.maps.TrafficModel.OPTIMISTIC
                        }
                    };

                    const MatRes = await MatrixService.getDistanceMatrix(request);
                    const { rows } = MatRes;
                    const ETA = rows[0].elements[0].duration_in_traffic.text;
                    setETA(ETA)
                }
                else if (driver_details && driver_details?.location && driver_details?.location?.coordinates && driver_details?.location?.coordinates.length) {
                    direction = await directionsRequest({
                        DirectionsService,
                        origin: {
                            lat: driver_details?.location?.coordinates[1],
                            lon: driver_details?.location?.coordinates[0],
                        },
                        destination: {
                            lat: destinations[0].coordinates.lat,
                            lon: destinations[0].coordinates.lon,
                        }
                    })

                    const request = {
                        origins: [{
                            lat: driver_details?.location?.coordinates[1],
                            lng: driver_details?.location?.coordinates[0],
                        }],
                        destinations: [{
                            lat: destinations[0].coordinates.lat,
                            lng: destinations[0].coordinates.lon,
                        }],
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        unitSystem: window.google.maps.UnitSystem.METRIC,
                        avoidHighways: false,
                        avoidTolls: false,
                        drivingOptions: {
                            departureTime: new Date(),
                            trafficModel: window.google.maps.TrafficModel.OPTIMISTIC
                        }
                    };

                    const MatRes = await MatrixService.getDistanceMatrix(request);
                    const { rows } = MatRes;
                    const ETA = rows[0].elements[0].duration_in_traffic.text;
                    setETA(ETA)
                }
                else {
                    direction = await directionsRequest({
                        DirectionsService,
                        origin: {
                            lat: origins[0].coordinates.lat,
                            lon: origins[0].coordinates.lon,
                        },
                        destination: {
                            lat: destinations[0].coordinates.lat,
                            lon: destinations[0].coordinates.lon,
                        }
                    })
                }

                await delay(DIRECTION_REQUEST_DELAY)
                // tempDirectionsToOrigin.push(direction)

                directionsRenderer.setMap(map)
                directionsRenderer.setDirections(direction)
                // setDirections(tempDirectionsToOrigin)
                if (driver) {
                    setTimeout(() => {
                        directionsRenderer.setOptions({ preserveViewport: true })
                    }, 5000)
                }
            }
            fetchDirections()
        }
    }, [destinations, origins, setDirections, JSON.stringify(driver), JSON.stringify(coordinates), window.google])

    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        setMap(map)
    };
    return (
        // <GoogleMap
        //     ref={mapRef}
        //     defaultZoom={DEFAULT_ZOOM}
        //     defaultCenter={DEFAULT_CENTER}
        //     defaultOptions={{ ...DEFAULT_MAP_OPTIONS }}
        // >
        <div style={{ height: `400px` }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyC4ZjtSPEgYJXt7cvJG_-mTrEHLfe9NqLU" }}
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            >

                {ETA && !delivered ?
                    <div
                        lat={driver?.lat || currentDriver?.lat}
                        lng={driver?.lon || currentDriver?.lon}
                        style={{ backgroundColor: 'white', padding: `5px`, width: 60, display: 'flex', flex: 1 }}>
                        <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                            ETA {ETA}
                        </div>
                    </div>
                    : null}
                {/* {origins.map(({ coordinates: { lat, lon: lng }, id }) => (
                <img
                    key={id}
                    position={{ lat, lng }}
                    icon={{
                        url: pickup,
                        scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
                    }}
                />
            ))} */}

            </GoogleMapReact>
        </div>

        //     {driver || currentDriver ?
        //         <Marker
        //             position={{ lat: driver?.lat || currentDriver?.lat, lng: driver?.lon || currentDriver?.lon }}
        //             icon={{
        //                 url: driverIcon,
        //                 scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
        //             }}
        //         >
        //             
        //         </Marker> : null
        //     }
        //     {/* {directions.length && directions.map((direction, i) => (
        //         <DirectionsRenderer
        //             ref={dirRef}
        //             key={i}
        //             directions={direction}
        //             options={DIRECTIONS_OPTIONS}
        //         />
        //     ))} */}
        // </GoogleMap>
    )
})

export default MapContainer;