export const MOCK_DESTINATIONS_DATA = [
    {
        // id: 1,
        // title: 'Falun',
        coordinates: { lat: 60.610361157011646, lon: 15.63610136902125 },
        // src: 'https://i.pinimg.com/originals/09/9e/60/099e600bcfa057bf1c9ecdcce0ad529c.jpg',
    },
]
export const MOCK_ORIGINS_DATA = [
    {
        // id: 2,
        // title: 'Uppsala',
        coordinates: { lat: 59.86700302991904, lon: 17.639901897585162 },
        // src: 'https://d3aux7tjp119y2.cloudfront.net/images/oscar-ekholm-DC50Oj2m4CY-unsplash-CMSTemplate.width-1650_5AWSVQc.jpg',
    },
]

export const MAP_SETTINGS = {
    DEFAULT_MAP_OPTIONS: {
        scrollwheel: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
    },
    DEFAULT_CENTER: { lat: 57, lng: 20 },
    DEFAULT_ZOOM: 4,
    MARKER_SIZE: 35,
    PIXEL_OFFSET: {
        MARKER: {
            X: 0,
            Y: -35,
        },
    },
    DIRECTIONS_OPTIONS: { suppressMarkers: true, preserveViewport: true },
}

// export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC4ZjtSPEgYJXt7cvJG_-mTrEHLfe9NqLU&callback=initAutocomplete`