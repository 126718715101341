import logo from './assets/images/logo.png';
import './App.css';
import React from 'react';

class App extends React.Component {

  render() {
    return (
      <div className="App" >
        <div style={{ margin: '50px auto', width: '30%' }}>
          <img src={logo} alt="logo" style={{ margin: '0 30%', width: '30%' }} />
          <h5>Welcome to <code>Getchya LLC</code></h5>
        </div>
      </div>
    );
  }
}

export default App;
