import React from 'react';
import axios from 'axios';
import logo from '../../assets/images/logo.png'
import io from 'socket.io-client';
import Map from '../Map';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import scooter from '../../assets/images/scooter.svg'
import marker from '../../assets/images/marker.svg'
import { Launcher } from './es'
import moment from "moment";
const Chat = require("twilio-chat");

const BASEURL = "https://api-v3.getchya.app";
// const BASEURL = "http://35.170.18.248:3003";
const callStatusArray = [1, 2, 3, 4, 5, 11, 6]
const cancelStatusArray = [1, 2, 3]
const pointsArray = [
    {
        lat: 30.7042912, lon: 76.7104408
    }
    ,
    {
        lat: 30.705281, lon: 76.709006
    }
    ,
    {
        lat: 30.707106, lon: 76.707474
    }
    ,
    {
        lat: 30.705879, lon: 76.705446
    }
    ,
    {
        lat: 30.704357, lon: 76.703140
    }
    ,
    {
        lat: 30.703010, lon: 76.701080
    }
    ,
    {
        lat: 30.701590, lon: 76.698773
    }//
    ,
    {
        lat: 30.698887, lon: 76.694385
    }//
    ,
    {
        lat: 30.697300, lon: 76.691960
    }//
    ,
    {
        lat: 30.695742, lon: 76.689318
    }
    ,
    {
        lat: 30.702033, lon: 76.683834
    }
    ,
    {
        lat: 30.701069, lon: 76.679089
    }

]
let count = 0

class Order extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            origin: [],
            destination: [],
            driver: null,
            directions: [],
            orderId: null,
            token: null,
            alertShow: false,
            errMsg: null,
            searching: false,
            rideStart: false,
            delivered: false,
            order: [],
            socket: null,
            driver_details: null,
            cancellation_reason: '',
            toggleCancelModal: false,
            orderCancelled: false,
            channel: null,
            messageList: [],
            newMessagesCount: 0,
            messages: null,
            scrollOrNot: true,
            coordinates: '',
            orderStatus: 0
        }
        this.onChangeValue = this.onChangeValue.bind(this)
    }

    simulateLocations = () => {
        setTimeout(() => {
            this.setState({ driver: pointsArray[count], coordinates: pointsArray[count].lat + pointsArray[count].lon })
            count++
            if (count < pointsArray.length) {
                this.simulateLocations()
            }
            else {
                count = 0
                this.simulateLocations()
            }
        }, 3000)
    }

    componentDidMount = async () => {
        // this.simulateLocations()

        const token = this.props.match.params.token;
        // {transports: ['websocket'], upgrade: false}

        const socket = io(BASEURL, {
            withCredentials: false,
            query: { auth_token: token },
            transports: ['websocket'],
        });
        this.setState(prevState => {
            return { ...prevState, socket }
        })
        this.getOrderDetails()
        socket.on('connect', function () {
            console.log("Socket Connnected");
            socket.emit('socket-id-update', (result) => {
                if (result.status !== 200) {
                    console.log('-------- err - - -Socket Updated on server  ---------', result);
                } else {
                    console.log('---------- Socket Updated on server ------', result);
                }
            });
        });

        // socket.on('disconnect', function () {
        //     console.log("Socket disConnnected");
        // });

        socket.on('order-request-response-to-customer', (data) => {
            console.log(data.data, '---------- order-request-response-to-customer -- data ------', data);
            console.log('---------- data.status_by_driver ------', data.data.status_by_driver);
            this.getOrderDetails()
            this.setState({ orderStatus: data?.data?.status_by_driver })

            if (data.data.status_by_driver === 2 && data.status === true) {

                // Payment Submit
                socket.emit('update-order-payment-detail', { status: true, order_id: orderId, total_amount: this.state.order.transaction_details.amount, transaction_id: this.state.order.transaction_details.amount.transaction_id }, (result) => {
                    if (result.status !== 200) {
                        console.log('-------- err ---- You transfered money for order  ------', result);
                    } else {
                        console.log('---------- You transfered money for order ------', result);
                    }
                });
            }
            // 
            if (data.data.status_by_driver === 2 && data.status !== true) {
                // console.log('---------- Request rejected By driver------');
                this.setState({ errMsg: "Going to Pickup", searching: false, showAlert: true })
            }

            if (data.data.status_by_driver === 3) {
                // Accept pickup Request
                socket.emit('update-request-status-by-customer', { order_id: orderId, status: true, status_by_customer: 3 }, (result) => {
                    if (result.status !== 200) {
                        console.log('-------- err - --  Pickup permision Approve --------', result);
                    } else {
                        console.log('---------- Pickup permision Approve ------', result);
                    }
                });
                this.setState({ errMsg: "Picked Up by Driver", searching: false, showAlert: true, rideStart: true });
            }

            if (data.data.status_by_driver === 11) {
                console.log('---------- Picked Up by Driver / On my way ------');
                this.setState({ errMsg: "Driver is on the way...", searching: false, showAlert: true, rideStart: true })
            }

            if (data.data.status_by_driver === 5) {
                console.log('---------- Reached Destination by Driver ------');
                this.setState({ errMsg: "Reached Destination Point", searching: false, showAlert: true, rideStart: true });
            }

            if (data.data.status_by_driver === 6) {
                console.log('---------- Delivered by Driver------');
                this.setState({ errMsg: "Order is delivered", showAlert: true, delivered: true, rideStart: false })
            }

        });
        socket.on('cancel-order-by-driver', (data) => {
            this.setState({ orderCancelled: true, errMsg: 'Order is cancelled' })
        })

        const orderId = this.props.match.params.id;

        let chatToken = "";
        try {
            chatToken = await this.getToken();
        } catch {
            // throw new Error("Unable to get token, please reload this page");
        }
        if (chatToken) {
            const client = await Chat.Client.create(chatToken);
            console.log("chat token")
            client.on("tokenAboutToExpire", async () => {
                const chatToken = await this.getToken();
                client.updateToken(chatToken);
            });

            client.on("tokenExpired", async () => {
                const chatToken = await this.getToken();
                client.updateToken(chatToken);
            });
            client.on("channelJoined", async (channel) => {
                // getting list of all messages since this is an existing channel
                const messages = await channel.getMessages();
                this.setState({ messages })
                let messageArray = []

                messages && messages.items && messages.items.length && messages.items.map((obj) => {
                    let msg = {
                        author: obj.author == this.state.identity ? 'me' : 'them',
                        type: 'text',
                        data: { text: obj.body, time: this.formatDate(obj.dateCreated) },
                        authorName: obj.author == this.state.identity ? this.state.identity : this.state.driver_details?.email
                    }
                    messageArray.push(msg)
                })
                if (messageArray.length) {
                    console.log("channelJoined", messageArray)
                    this.setState({ messageList: messageArray || [] });
                }
            });

            try {
                const channel = await client.getChannelByUniqueName(orderId);
                this.joinChannel(channel);
            } catch (err) {
                try {
                    const channel = await client.createChannel({
                        uniqueName: orderId,
                        friendlyName: orderId,
                    });

                    this.joinChannel(channel);
                } catch {
                    throw new Error("Unable to create channel, please reload this page");
                }
            }
        }

    }

    formatDate = (date) => {
        return moment(date).format("hh:mm A")
    }

    scrollToTop = () => {
        const { messages, messageList } = this.state

        if (messages && messages.hasPrevPage) {
            messages.prevPage().then((msgs) => {
                this.setState({ scrollOrNot: false })
                this.setState({ messages: msgs })
                let messageArray = []
                msgs && msgs.items && msgs.items.length && msgs.items.map((obj) => {
                    let msg = {
                        author: obj.author == this.state.identity ? 'me' : 'them',
                        type: 'text',
                        data: { text: obj.body, time: this.formatDate(obj.dateCreated) },
                        authorName: obj.author == this.state.identity ? this.state.identity : this.state.driver_details?.email
                    }
                    messageArray.push(msg)
                })
                // this.setState({ messageList: [...messageArray, ...messageList] });
            })
        }
    }



    _sendMessage(text) {
        if (text.length > 0) {
            // this.setState({
            //     messageList: [...this.state.messageList, {
            //         author: 'me',
            //         type: 'text',
            //         data: { text }
            //     }]
            // })
        }
    }

    getOrderDetails = () => {
        const orderId = this.props.match.params.id;
        const token = this.props.match.params.token;
        if (orderId && token) {
            axios.get(`${BASEURL}/webhook/order/${orderId}`)
                .then(res => {
                    const order = res.data.data;
                    console.log("order > ", order);
                    let driver_details = order.driver_details
                    let data = order?.order_delivery_detail
                    let delivered = order.order_delivery_detail && order.order_delivery_detail.status_by_driver === 6 ? true : false;
                    let orderCancelled = order?.order_delivery_detail?.status_by_driver === 8 || order?.order_delivery_detail?.status_by_customer === 6 ? true : false
                    const { pickup_lat, pickup_long, drop_lat, drop_long } = order.location_details
                    const origin = [{ coordinates: { lat: pickup_lat, lon: pickup_long } }];
                    const destination = [{ coordinates: { lat: drop_lat, lon: drop_long } }];
                    this.setState({ order, orderId, origin, destination, delivered, driver_details, orderCancelled });
                    this.setState({ orderStatus: data?.status_by_driver })
                    if (data?.status_by_driver === 2) {
                        // console.log('---------- Request rejected By driver------');
                        this.setState({ errMsg: "Going to Pickup", searching: false, showAlert: true })
                    }

                    if (data?.status_by_driver === 3) {
                        // Accept pickup Request
                        this.setState({ errMsg: "Picked Up by Driver", searching: false, showAlert: true, rideStart: true });
                    }

                    if (data?.status_by_driver === 11) {
                        console.log('---------- Picked Up by Driver / On my way ------');
                        this.setState({ errMsg: "Driver is on the way...", searching: false, showAlert: true, rideStart: true })
                    }

                    if (data?.status_by_driver === 5) {
                        console.log('---------- Reached Destination by Driver ------');
                        this.setState({ errMsg: "Reached Destination Point", searching: false, showAlert: true, rideStart: true });
                    }

                    if (data?.status_by_driver === 6) {
                        console.log('---------- Delivered by Driver------');
                        this.setState({ errMsg: "Order is delivered", showAlert: true, delivered: true, rideStart: false })
                    }

                    if (data?.status_by_driver === 8 || data?.status_by_customer === 6) {
                        console.log('---------- Delivered by Driver------');
                        this.setState({ errMsg: "Order is cancelled", showAlert: true, delivered: true, rideStart: false, })
                    }
                })
        }
    }

    cancelOrder = () => {
        this.setState({ toggleCancelModal: false })
        const orderId = this.props.match.params.id;
        const token = this.props.match.params.token;
        let data = JSON.stringify({ "order_id": orderId, "cancellation_reason": this.state.cancellation_reason });
        let config = {
            method: 'post',
            url: `${BASEURL}/v1/orders/cancelOrder`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: data
        };
        let _this = this

        axios(config)
            .then(function (response) {
                console.log(response.status, "=======")
                if (response.status == 200) {
                    _this.setState({ orderCancelled: true, errMsg: "Order is cancelled" })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    handleOrderDelivery = () => {
        this.setState({ searching: true, errMsg: "Searching for Driver..." })
        this.state.socket.emit('send-request-to-backend', { order_id: this.state.orderId }, (result) => {
            console.log("result", result);
            if (result.status !== 200) {
                this.setState({ errMsg: result.message, showAlert: true, searching: false, })
            } else if (result.message === "cancel request") {
                this.setState({ errMsg: "driver is not available at this location", searching: false, showAlert: true })
            } else if (result.message === "send request") {
                // this.setState({ errMsg: "Driver is on the way", searching: false, showAlert: true, rideStart: true })
            }
        });
        this.initSocket();
    }

    initSocket = () => {
        this.state.socket.on('driver-location-for-customer', (data) => {
            console.log("lisening | driver-location-for-customer")
            if (data.status === 200) {
                const [lon, lat] = data.data.coordinates;
                const driver = { lat, lon };
                localStorage.setItem('driver', JSON.stringify(driver))
                this.setState({ driver, coordinates: lat + lon });
            }
            console.log('---------- Delivered Location ------', data);
        });
    }

    hideAlert = () => {
        this.setState({ showAlert: false, errMsg: null });
    }

    onChangeValue(event) {
        this.setState({ cancellation_reason: event.target.value })
    }

    renderScooter = () => {
        return (
            <img src={scooter} className='scooter' alt="My Happy SVG" />
        )
    }

    getToken = async () => {
        const token = this.props.match.params.token;
        let obj = JSON.stringify({ "device_token": "32b2dfb032b2dfb0" });
        let config = {
            method: 'post',
            url: `${BASEURL}/v1/twilio/generateToken`,
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            data: obj
        };
        const response = await axios(config)
        const { data, status } = response;
        this.setState({ identity: data?.data?.identity })
        return data?.data?.token;
    }

    renderTimeline = () => {
        const { order, orderStatus } = this.state
        return (
            <ul className="events">
                <li>
                    <span className={`${orderStatus >= 2 ? 'active' : ''}`}>
                        Accepted pickup request<img src={marker} className='marker' /></span></li>
                <li>
                    <span className={`${orderStatus >= 2 ? 'active' : ''}`}>
                        {orderStatus == 2 && this.renderScooter()}
                        Going to Pickup</span></li>
                <li>
                    <span className={`${orderStatus >= 3 ? 'active' : ''}`}>
                        {/* {orderStatus == 3 && this.renderScooter()} */}
                        Reached Pickup Point</span></li>
                <li>
                    <span className={`${orderStatus >= 3 ? 'active' : ''}`}>
                        {orderStatus == 3 && this.renderScooter()}
                        Picked Up</span></li>
                <li>
                    <span className={`${orderStatus >= 11 || orderStatus >= 5 ? 'active' : ''}`}>
                        {orderStatus == 11 && this.renderScooter()}
                        On my way...</span></li>
                <li>
                    <span className={`${orderStatus == 6 ? 'active' : ''}`}>
                        Reached destination<img src={marker} className='marker2' /></span></li>
            </ul>)
    }


    joinChannel = async (channel) => {
        if (channel.channelState.status !== "joined") {
            await channel.join();
        }

        this.setState({
            channel: channel,
            loading: false
        });

        channel.on("messageAdded", this.handleMessageAdded);
    };

    handleMessageAdded = (message) => {
        const { messageList, channel } = this.state;

        const newMessagesCount = this.state.isOpen ? 0 : this.state.newMessagesCount + 1;
        let msg = {
            author: message.author == this.state.identity ? 'me' : 'them',
            type: 'text',
            data: { text: message.body, time: this.formatDate(message.dateCreated) },
            authorName: message.author == this.state.identity ? this.state.identity : this.state.driver_details?.email
        }
        this.setState({
            scrollOrNot: true,
            messageList: [...messageList, msg],
            newMessagesCount: newMessagesCount
        },
        );
    };

    _onMessageWasSent(message) {
        const { channel } = this.state;
        channel.sendMessage(String(message.data.text).trim());
    }

    _handleClick() {
        this.setState({
            isOpen: !this.state.isOpen,
            newMessagesCount: 0
        });
    }

    render() {
        const { order, driver_details, orderCancelled, messageList, coordinates, scrollOrNot } = this.state
        let status = order?.order_delivery_detail?.status_by_driver
        return (
            <div>
                <header>
                    <div className="container">
                        <h3 className="mainHeading">
                            <img src={logo} alt="Getchya logo" style={{ marginRight: "30px", width: "80" }} />
                            <span>Get your order delivered by Getchya</span>
                        </h3>
                    </div>
                </header>

                <Popup style={{ borderRadius: 20 }} open={this.state.toggleCancelModal} position="center" onClose={() => this.setState({ toggleCancelModal: false })}>
                    <div onChange={this.onChangeValue} style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                        <h6>Please choose cancellation reason</h6>
                        <div><input type="radio" value="Male" name="gender" /> Driver denied to go to destination</div>
                        <div><input type="radio" value="Female" name="gender" /> Driver denied to come to pickup</div>
                        <div><input type="radio" value="Other" name="gender" /> Expected to shorter wait time</div>
                        <div><input type="radio" value="Other" name="gender" /> Unable to contact driver</div>
                        <div><input type="radio" value="Other" name="gender" /> My reason is not listed</div>
                        <div className="ga-submit mt-2">
                            <button className="btn" onClick={() => this.cancelOrder()}>{'Submit'}</button>
                        </div>
                    </div>
                </Popup >
                <section className="greyBg">
                    <div className="container">
                        {order && order.location_details ?
                            <div>
                                <div className="row">
                                    <div className="col-md-5">
                                        {this.state.showAlert &&
                                            <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                <strong>{this.state.errMsg}</strong>
                                                <button type="button" className="close" onClick={this.hideAlert} data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                        }

                                        <div className="cartSummary boxLayout">
                                            <h6 className="sectionHeading">Order Summary</h6>
                                            <div className="tabularData">
                                                <p><strong>OrderID: </strong><span>{order.order_id_short}</span></p>

                                                <p><strong>Package Description: </strong><span>{order.description}</span></p>
                                                <p><strong>Size of Package: </strong><span> {order._id}</span></p>
                                                <p><strong>Pickup Location: </strong><span>{order.location_details.pickup_address}</span></p>
                                                <p><strong>Drop Location: </strong><span>{order.location_details.drop_address}</span></p>
                                                <p><strong>Delivery Type: </strong><span>{order.delivery_type}</span></p>
                                                <p><strong>Note for Driver: </strong><span>{order.notes_for_driver}</span></p>
                                                <p><strong>Schedule for now: </strong><span>Yes</span></p>
                                                <p><strong>Cost: </strong><span>$ {order.location_details.price}</span></p>
                                            </div>
                                            {this.state.errMsg ?
                                                <React.Fragment>
                                                    {this.state.searching ? <div className="loader"></div> : null}
                                                    <div className="ga-submit">
                                                        <button className="btn">{this.state.errMsg}</button>
                                                    </div>
                                                    {!orderCancelled && status >= 2 ? <div className="ga-submit cancel mt-4">
                                                        {callStatusArray.includes(status) ? <button className="btn">
                                                            <a style={{ lineHeight: '2px' }} href={`tel:+${driver_details?.country_code + driver_details?.phone_number}`}>Call +{driver_details?.country_code + driver_details?.phone_number}</a>
                                                        </button> : null}
                                                        {cancelStatusArray.includes(status) ? <button className="btn ml-4" onClick={() => {
                                                            this.setState({ toggleCancelModal: true })
                                                        }}>Cancel</button> : null}
                                                    </div> : null}
                                                </React.Fragment>
                                                : <div className="ga-submit">
                                                    <button className="btn" onClick={this.handleOrderDelivery}>Get it delivered by Getchya</button>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        {/* <MyMapComponent isMarkerShown /> */}
                                        <Map coordinates={coordinates} driver_details={driver_details} origins={this.state.origin} destinations={this.state.destination} driver={this.state.driver} rideStart={this.state.rideStart} delivered={this.state.delivered} />
                                    </div>
                                    <div className="col-md-3">
                                        {this.renderTimeline()}
                                    </div>
                                </div>
                                <button className="btn ml-4" onClick={() => {
                                    this.setState({ toggleCancelModal: true })
                                }}>Cancel</button>
                                {/* <div className="row">

                                </div> */}
                            </div>
                            : <div className="row">
                                <div className="col-md-12">
                                    <h4>
                                        Order is not valid...
                                    </h4>
                                </div>
                            </div>
                        }
                    </div>
                </section>

                {driver_details ?
                    <Launcher
                        scrollOrNot={scrollOrNot}
                        scrollToTop={this.scrollToTop.bind(this)}
                        agentProfile={{
                            teamName: 'Getchya' //driver_details?.first_name + " " + driver_details?.last_name
                        }}
                        handleClick={this._handleClick.bind(this)}
                        newMessagesCount={this.state.newMessagesCount}
                        onMessageWasSent={this._onMessageWasSent.bind(this)}
                        messageList={messageList}
                        showEmoji={false}
                        isOpen={this.state.isOpen}
                        showEmoji
                    /> : null}
            </div >
        );
    }
}

export default Order